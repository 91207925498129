.container-certification {
  padding: 150px 0;

  @media (max-width: 768px) {
    padding-top: 90px;
    padding-bottom: 100px;
  }

  @media (max-width: 560px) {
    padding: 100px 0;
  }

  .container-page {
    .certification-text {
      margin-bottom: 41px;

      @media (max-width: 560px) {
        margin-bottom: 45.45px;
      }

      .text-certification {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #e75e24;
        margin-bottom: 10px;

        @media (max-width: 560px) {
          font-size: 15px;
          line-height: 20px;
        }
      }

      .title-certification {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 52px;
        color: #212e5e;
        text-align: center;
        margin-bottom: 15px;

        @media (max-width: 560px) {
          font-size: 36px;
          line-height: 43px;
        }
      }

      .description-certification {
        font-family: 'Open Sans';
        max-width: 740px;
        font-style: normal;
        font-weight: 400;
        color: #8d9baa;
        font-size: 16px;
        text-align: center;
        line-height: 26px;
        margin: auto;
        margin-bottom: 20px;

        @media (max-width: 560px) {
          font-size: 15px;
          line-height: 23px;
        }
      }

      .link-certification {
        align-items: center;
        display: flex;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #e75e24;
        transition: all 0.5s;
        border-radius: 50%;
        width: max-content;
        margin: auto;

        svg {
          border-radius: 50%;
          transition: all 0.5s;
          margin-left: 11px;

          path {
            transition: all 0.5s;
            stroke: #e75e24;
          }
        }

        &:hover {
          color: #F9893D;

          svg {
            background: #F9893D;

            path {
              stroke: #fff;
            }
          }
        }
      }
    }

    .container-imgs {
      display: flex;
      justify-content: center;

      .img-certification {
        margin-right: 45px;
        width: 121px;
        height: 111px;
        object-fit: contain;

        @media (max-width: 604px) {
          width: 57px;
          margin-right: 30px;
          object-fit: contain;
        }

        @media (max-width: 374px) {
          margin-right: 8%;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
